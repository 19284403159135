import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js';

// Rejestracja wymaganych komponentów
ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale);

const ChartComponent = ({ data }) => {
  if (!data || !data.labels.length) return null;

  const chartData = {
    labels: data.labels, // Etykiety osi X
    datasets: [
      {
        label: 'Histogram pozycji', // Opis zestawu danych
        data: data.values, // Dane dla osi Y
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Histogram pozycji w wyszukiwarkach',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Zakres pozycji',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Liczba wyszukiwań',
        },
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default ChartComponent;
